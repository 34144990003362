import React, { useState, useEffect, useCallback } from "react";
import './claim.css';
import BigNumber from "bignumber.js";
import { byDecimals } from "../../helpers/bignumber";
import { useConnectWallet } from "../../pages/redux/connectWallet";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

// web3 calls
import { useBalanceOfNFT1, useTokensOfOwner1, useTokensOfOwner2, useDeposit } from "../redux/hooks";
import { claimAbi } from "../../configure/abi";

const ClaimContent = ({ index, npool }) => {

  const { address, networkId, web3 } = useConnectWallet();
  const [tokenIds1, setTokenIds1] = useState([]);
  const [tokenIds2, setTokenIds2] = useState([]);
  const [myClaimRewards, setClaimMyRewards] = useState("0");
  const [fetching, setFetching] = useState(true);

  const myNfts1 = useBalanceOfNFT1(npool.tokenAddress1);
  const myNfts2 = useBalanceOfNFT1(npool.tokenAddress2);

  const tokenIdsa = useTokensOfOwner1(npool.tokenAddress1);
  const tokenIdsb = useTokensOfOwner2(npool.tokenAddress2);

  const fetchRewards = useCallback(async () => {
    let newRewards = [];
    try {
      const contract = new web3.eth.Contract(
        claimAbi,
        npool.earnContractAddress
      );

      newRewards = await contract.methods
        .rewards(tokenIds1, tokenIds2)
        .call({ from: address });

      setClaimMyRewards(newRewards)

    } catch (error) {
      console.log("failed");
      setFetching(true);
    }
  }, [web3, npool.earnContractAddress, tokenIds1, tokenIds2, address]);



  useEffect(() => {
    const getNFTInfo = async () => {
      try {
        if (address && networkId && (myNfts1 > 0 || myNfts2 > 0) && fetching === true) {

          let nftids1mod = []
          let nftids2mod = []

          if (tokenIdsa.length > 0) {
            nftids1mod = tokenIdsa.map(Number)
          }
          if (tokenIdsb.length > 0) {
            nftids2mod = tokenIdsb.map(Number)
          }
          setTokenIds1(nftids1mod)
          setTokenIds2(nftids2mod)

          setFetching(false);
        }
      } catch (error) {
        console.log(error)
      }
    };
    getNFTInfo();
  }, [address, fetching, myNfts1, myNfts2, networkId, tokenIdsa, tokenIdsb]);

  useEffect(() => {
    const getClaimInfo = async () => {
      try {
        if (fetching === false && (tokenIds1.length > 0 || tokenIds2.length > 0)) {

          await fetchRewards()
        }
      } catch (error) {
        console.log(error)
        getClaimInfo();
      }
    };
    getClaimInfo();
  }, [address, fetchRewards, fetching, tokenIds1, tokenIds1.length, tokenIds2, tokenIds2.length]);

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress
  );


  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <div className='mainContent claim'>
      <section className='titleDescrImage'>
        <div className='titleDescr'>
          <AnimationOnScroll animateIn="animate__shakeY">
            <h2>Hump Board<span>Claim your $Hump for holding StrayDogz and DHNFTs NFTs</span></h2>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__shakeY">
            <p>
              <span>5% of $HUMP token and 1% of trades volume</span> is dedicated to reward NFT Holders. DHNFTs have 20% of claim power compared to StrayDogz NFTs
            </p>
          </AnimationOnScroll>
          <div className='buyNFTs'>
            <AnimationOnScroll animateIn="animate__bounceIn">
              <div className='buySD'>
                <a href="https://opensea.io/collection/straydogz-official" target="_blank" rel="noopener noreferrer">Buy StrayDogz</a>
                <div className='floorprice'>
                  fp
                  <span></span>
                  ETH
                </div>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__bounceIn">
              <div className='buyDH'>
                <a href="https://opensea.io/collection/dogz-hate-nfts" target="_blank" rel="noopener noreferrer">Buy DHNFTs</a>
                <div className='floorprice'>
                  fp
                  <span></span>
                  ETH
                </div>
              </div>
            </AnimationOnScroll>
          </div>
          <AnimationOnScroll animateIn="animate__lightSpeedInRight">
            <div className='tokenStats'>
              <div className='vol'>
                <h3>24h Volume</h3>
                <div>$
                  <span className='valueStats'>??</span>
                </div>
              </div>
              <div className='claimed'>
                <h3>HUMP Claimed</h3>
                <div>
                  <span className='valueStats'>??</span>
                </div>
              </div>
              <div className='price'>
                <h3>HUMP Price</h3>
                <div>$
                  <span className='valueStats'>??</span>
                  <span className='valueamountHump'>per 1k HUMP</span>
                </div>
              </div>
              <div className='mc'>
                <h3>Market Cap</h3>
                <div>$
                  <span className='valueStats'>??</span>
                </div>
              </div>
              <div className='holders'>
                <h3>Holders</h3>
                <div>
                  <span className='valueStats'>??</span>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__shakeY">
            <div className='connectedWallet'>Connected Wallet: <span>{`${address.slice(0, 6)}...${address.slice(-4)}`}</span></div>
            <div className='youOwn'>
              <div>You Own</div>
              <div className='amountNFT'>
                <div className='amountSD'>
                  <span>{myNfts1} {' '} </span>
                  StrayDogz
                </div>
                <div className='amountDH'>
                  <span>{myNfts2} {' '} </span>
                  DHNFTs
                </div>
              </div>
              <div className='totalClaimed'>{/* You total Claimed:  */}<span>{/* 456,456,215 */}</span> {/* HUMP */}</div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__zoomInUp">
            <div className="claimTokens">
              <img className="tokensImg" src={require('../../imgs/tokens.png')} alt=""></img>
              <div className="tokensclaim">
                <div className='titleClaim'>Claimable Amount</div>
                <div className="amountTokens">{forMat(byDecimals(myClaimRewards)).toFixed(2)} <span>HUMPs</span></div>
                <button
                  type='button'
                  className='claimHump'

                  onFocus={(event) =>
                    event.stopPropagation()
                  }
                  onClick={(event) => {
                    onDeposit(tokenIds1, tokenIds2);
                  }}
                  disabled={isDepositPending || fetching === true || (tokenIds1.length === 0 && tokenIds2.length === 0) || forMat(byDecimals(myClaimRewards)) === 0}
                >
                  {tokenIds1.length === 0 && tokenIds2.length === 0 ?
                    (
                      <span className="sr-only">No NFTs owned</span>
                    ) :
                    isDepositPending || fetching === true ?
                      (<div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      ) : (
                        <span className="sr-only">Claim $HUMP</span>
                      )
                  }
                </button>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
      </section>
    </div>
  );
};

export default ClaimContent;