import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";
import pagesReducer from "../pages/redux/reducer";
import claimReducer from "../claim/redux/reducer";
import commonReducer from "./redux/reducer";

const reducerMap = {
  router: connectRouter(history),
  pages: pagesReducer,
  claim: claimReducer,
  common: commonReducer,
};

export default combineReducers(reducerMap);
