import React from "react";
import ReactDOM from 'react-dom/client';
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from './reportWebVitals';

if ('ethereum' in window) {
  ;(window.ethereum).autoRefreshOnNetworkChange = true
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Root />
  </>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals();