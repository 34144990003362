import WalletConnectProvider from "@walletconnect/web3-provider";
import { allNetworks } from '../network';
import WalletLink from "walletlink";

export const appNetworkId = window.REACT_APP_NETWORK_ID;

const networkTxUrls = {
  1: hash => `https://etherscan.io/tx/${hash}`,
};


const networkFriendlyName = {
  1: 'ETH',
};

export const getNetworkConnectors = () => {
  switch (process.env.REACT_APP_NETWORK_ID) {
    case "1":
      return {
        network: "",
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: "Metamask",
            },
          },
          "custom-wc-eth": {
            display: {
              logo: "wallet-connect.svg",
              name: "Wallet Connect",
              description: ("Connect or scan your wallet"),
            },
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: "https://mainnet.infura.io/v3/c0216574288e4608b6b4a07609bdd568",
              },
            },
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);

              await provider.enable();

              return provider;
            },
          },
          "custom-cb-eth": {
            display: {
              logo: "coinbase.png",
              name: "Coinbase Wallet",
              description: ("Connect to your Coinbase Wallet"),
            },
            options: {
              appName: "hump",
              darkMode: true,
            },
            package: WalletLink,
            connector: async (ProviderPackage, options) => {
              const walletLink = new ProviderPackage(options);

              const provider = walletLink.makeWeb3Provider(
                "https://mainnet.infura.io/v3/c0216574288e4608b6b4a07609bdd568",
                1
              );

              await provider.enable();

              return provider;
            },
          },
        },
      };
      case "43113":
        return {
          network: "",
          cacheProvider: true,
          providerOptions: {
            injected: {
              display: {
                name: "Metamask",
              },
            },
            "custom-wc-eth": {
              display: {
                logo: "wallet-connect.svg",
                name: "Wallet Connect",
                description: ("Connect or scan your wallet"),
              },
              package: WalletConnectProvider,
              options: {
                rpc: {
                  1: "https://mainnet.infura.io/v3/",
                },
              },
              connector: async (ProviderPackage, options) => {
                const provider = new ProviderPackage(options);
  
                await provider.enable();
  
                return provider;
              },
            },
            "custom-cb-eth": {
              display: {
                logo: "coinbase.png",
                name: "Coinbase Wallet",
                description: ("Connect to your Coinbase Wallet"),
              },
              options: {
                appName: "hump",
                darkMode: true,
              },
              package: WalletLink,
              connector: async (ProviderPackage, options) => {
                const walletLink = new ProviderPackage(options);
  
                const provider = walletLink.makeWeb3Provider(
                  "https://mainnet.infura.io/v3/",
                  1
                );
  
                await provider.enable();
  
                return provider;
              },
            },
          },
        };
    default:
      return {};
  }
};

export const getNetworkFriendlyName = (networkId = window.REACT_APP_NETWORK_ID) =>
  networkFriendlyName[networkId];

export const getNetworkAppUrl = (networkId = window.REACT_APP_NETWORK_ID) =>
  window.location.protocol +
  "//" +
  window.location.host +
  window.location.pathname +
  "#" +
  allNetworks.find((n) => n.id === networkId)?.hash;
  export const getNetworkTxUrl = networkTxUrls[window.REACT_APP_NETWORK_ID];