import React from "react";
import './footer.css';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="infoFooter">
          <p>Please be aware that $HUMP is a meme asset with no intrinsic value or expectation of financial return.<br/>It has been created out of love for the community and for entertainment purposes only.</p>
          <p>© 2023 Hump.wtf, all rights reserved</p>
        </div>
        <div className="linkFooter">
          <a className="dextools" href="https://www.dextools.io/app/en/ether/pair-explorer/0x705bb9aa73e75b29f0e708a00efba9348c082d8c" target="_blank" rel="noopener noreferrer"></a>
        </div>
      </footer>
    </>
  );
};

export default Footer;