import React, { useState, useEffect } from 'react';
import './App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useConnectWallet, useDisconnectWallet } from "./redux/hooks";
import { createWeb3Modal } from "../web3";
import { SnackbarProvider } from "notistack";
import { Notifier } from '../common';
import NetworksProvider from '../components/NetworksProvider/NetworksProvider';
import "animate.css/animate.min.css";

function App({ children }) {

	const {
		connectWallet,
		web3,
		address,
		networkId,
		connected
	} = useConnectWallet();
	const { disconnectWallet } = useDisconnectWallet();
	const [web3Modal, setModal] = useState(null);

	useEffect(() => {
		setModal(createWeb3Modal());
	}, [setModal]);

	useEffect(() => {
		if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
			//connectWallet(web3Modal);
		}
	}, [web3Modal, connectWallet]);

	// Mozilla Bug by network request if you change the tab to another web3 Website
	/* 	useEffect(() => {
			if (
				web3 &&
				address &&
				!connectWalletPending &&
				Boolean(networkId !== 1)
	
			) {
				alert("Please connect to ETH Network!");
			}
		}, [web3, address, networkId, connectWalletPending]); */


	return (
		<>
			<NetworksProvider>
				<SnackbarProvider>
					<Header
						address={address}
						connected={connected}
						connectWallet={() => connectWallet(web3Modal)}
						disconnectWallet={() => disconnectWallet(web3, web3Modal)}
						networkId={networkId}
					/>
					{children}
					<Notifier />
					<Footer />
				</SnackbarProvider >
			</NetworksProvider>
		</>
	);
}

export default App;
