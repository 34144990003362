import React from "react";
import { useFetchPoolBalances } from "./redux/hooks";
import { useConnectWallet } from "../pages/redux/connectWallet";
import ClaimContent from "./content/claimContent";
import DefaultContent from "./content/defaultContent";

const Claim = () => {

  let { claimpools } = useFetchPoolBalances();
  const { networkId } = useConnectWallet();

  return (
    <>
      {claimpools.filter(pool => (pool.chainId === 1 && networkId === 1)).map((npool, index) => (
        <ClaimContent key={index} npool={npool} />
      ))}

      {networkId !== 1 && (
        <DefaultContent />
      )}

    </>
  );
};

export default Claim;