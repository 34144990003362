import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './home.css';

export default function HomePage(props) {
  return (
    <div className='mainContent'>
      <section className='titleDescrImage'>
        <div className='titleDescr'>
          <h2>
            <a href='/'>
              <img className='animate__animated animate__rubberBand' src={require('../imgs/logo.png')} alt="Hump Token - StrayDogz wtf"></img>
            </a>
          </h2>
          <AnimationOnScroll animateIn="animate__jackInTheBox">
            <div>
              <h2>StrayDogz are back stronger than ever!<span>Get ready for aggressive humpover of the blockchain.</span></h2>
              <p>
                Hump is ERC-20 utilized memecoin based on StrayDogz NFT collection rewarding NFT holders with high focus on gaming and entertainment utilization.
              </p>
              <a href='https://etherscan.io/address/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a' className='linkContract' target='_blank' rel="noopener noreferrer"><span>Contract:</span> 0xa44fb2638F53d7D7cAa8c67adD1eA98b60783c0A <i><img src={require('../imgs/link.png')} alt=""></img></i></a>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__lightSpeedInLeft">
            <a className='externalLink' href='https://app.uniswap.org/#/tokens/ethereum/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a' target='_blank' rel="noopener noreferrer">
              <button className='uniswapBuy'><i><img src={require('../imgs/uniswap.png')} alt=""></img></i>BUY HUMP</button>
            </a>
          </AnimationOnScroll>
        </div>
        <AnimationOnScroll animateIn="animate__shakeY">
          <img className='imgDescr' src={require('../imgs/leghump.png')} alt="StrayDogz Humping Leg"></img>
        </AnimationOnScroll>
      </section>
      <section className='rewarding'>
        <AnimationOnScroll animateIn="animate__bounceIn">
          <img className='imgDescr' src={require('../imgs/adoptMe.png')} alt="Adopt StrayDogz"></img>
        </AnimationOnScroll>
        <div className='titleDescr'>
          <AnimationOnScroll animateIn="animate__lightSpeedInRight">
            <h3>Rewarding<span>StrayDogz</span></h3>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__shakeY">
          <p>
            1% of trading volume splits between <a href='https://opensea.io/collection/straydogz-official' target='_blank' rel="noopener noreferrer">Straydogz</a> and <a href='https://opensea.io/collection/dogz-hate-nfts' target='_blank' rel="noopener noreferrer">DHNFTs</a> NFT holders.
            DHNFTs have 20% claim power of original Straydogz collection.
          </p>
          <p>
            Adopt StrayDog today, hump the chain and earn from success of the pack.
          </p>
          </AnimationOnScroll>
          <div className='linksOS'>
            <AnimationOnScroll animateIn="animate__swing">
              <div className='linkOS'>
                <img src={require('../imgs/StrayDogz.jpg')} alt=""></img>
                <h4><a href='https://opensea.io/collection/straydogz-official' target='_blank' rel="noopener noreferrer">buy StrayDogz</a></h4>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__swing">
              <div className='linkOS'>
                <img src={require('../imgs/DHNFTs.jpg')} alt="" ></img>
                <h4 className='dhnfts'><a href='https://opensea.io/collection/dogz-hate-nfts' target='_blank' rel="noopener noreferrer">buy DHNFTs</a></h4>
              </div>
            </AnimationOnScroll>
          </div>
          <AnimationOnScroll animateIn="animate__bounceIn">
            <a className='claimButton' href='#/claim/'>
              <button type='button' className='claimHumpBoard'>
                Claim Hump in Hump Board
              </button>
            </a>
          </AnimationOnScroll>
        </div>
      </section>
      <section className='tokenomic'>
        <div className='titleDescr'>
          <AnimationOnScroll animateIn="animate__lightSpeedInLeft">
            <h3>Hump<span>Tokenomic</span></h3>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__shakeY">
            <ul className='tokenInfo'>
              <li><span>token ticker:</span>$HUMP</li>
              <li><span>contract:</span><a className="contractAddr" href='https://etherscan.io/address/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a' target='_blank' rel="noopener noreferrer">0xa44fb2638F53d7D7cAa8c67adD1eA98b60783c0A</a></li>
              <li><span>supply:</span>69.696.969.420</li>
              <li><span>tax:</span>4% Buy, 4% Sell</li>
            </ul>
            <div>TAX distribution</div>
            <ul>
              <li>1% NFT Holders</li>
              <li>1% Marketing and development</li>
              <li>2% Auto LP</li>
            </ul>
          </AnimationOnScroll>
          <div className='piechartList'>
            <AnimationOnScroll animateIn="animate__rotateIn">
              <img src={require('../imgs/pieChart.png')} alt=""></img>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__slideInDown">
              <ul className='allos'>
                <li>70% liquidity</li>
                <li>10% Marketing and Development</li>
                <li>5% NFT Holders Initial Split</li>
                <li>5% Game Treasury</li>
                <li>10% Team</li>
              </ul>
            </AnimationOnScroll>
          </div>
        </div>
        <div className='tokenImgBuy'>
          <AnimationOnScroll animateIn="animate__bounceIn">
            <img className='imgDescr' src={require('../imgs/pumpit.png')} alt="Pump StrayDogz"></img>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__lightSpeedInRight">
            <a className='externalLink' href='https://app.uniswap.org/#/tokens/ethereum/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a' target='_blank' rel="noopener noreferrer">
              <button className='uniswapBuy'><i><img src={require('../imgs/uniswap.png')} alt=""></img></i>BUY HUMP</button>
            </a>
          </AnimationOnScroll>
        </div>
      </section>
      <section className='utility'>
        <AnimationOnScroll animateIn="animate__bounceIn">
          <img className='imgDescr' src={require('../imgs/scavengers.png')} alt="Adopt StrayDogz"></img>
        </AnimationOnScroll>
        <div className='titleDescr'>
          <AnimationOnScroll animateIn="animate__lightSpeedInRight">
            <h3>HUMPTILITY<span>PROGRAMMED</span></h3>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__zoomInUp">
            <div className='moreInfo'>MORE INFO COMING THOON!</div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__shakeY">
          <p>
            $HUMP combined with StrayDogz NFTs aim to become ecosystem of fun, gaming and engagement. Thanks to ours strategical partner Forge Universe, Gaming studio working for example on <a href='https://volteddragons.com/' target='_blank' rel="noopener noreferrer">VoltedDragons Battle Royale</a> game we aim to deliver lots of fun and gamification for Hump and StrayDogz Community.
          </p>
          </AnimationOnScroll>
          <div className='linksOS'>
            <AnimationOnScroll animateIn="animate__bounceIn">
              <div className='linkOS'>
                <a href='/'><img src={require('../imgs/hump.png')} alt="Hump Logo"></img></a>
              </div>
            </AnimationOnScroll>
            <img className='iconX' src={require('../imgs/icon_x.png')} alt=""></img>
            <AnimationOnScroll animateIn="animate__bounceIn">
              <div className='linkOS'>
                <a href='https://forgeuniverse.com' target='_blank' rel="noopener noreferrer"><img src={require('../imgs/forge.png')} alt="Forge Universe Logo" ></img></a>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </section>
    </div>
  );
};