import React, { useState, useEffect, useRef } from "react";
import './header.css';
import { renderIcon } from "@download/blockies";

const Header = (props) => {

  const { connected, address, connectWallet, disconnectWallet } = props;
  const canvasRef = useRef(null);
  const [shortAddress, setShortAddress] = useState("");
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current;
    renderIcon({ seed: address.toLowerCase() }, canvas);
    const updatedDataUrl = canvas.toDataURL();
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl);
    }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
    }
  }, [dataUrl, address, connected]);

  /*MENU MOBILE*/
  const [show, setShow] = React.useState();
  /*MENU MOBILE END*/

  return (
    <>
      <header>
        <nav className="menuDesktop">
          <h1><a href="/"><img src={require('../../imgs/token_img.png')} alt="$HUMP StrayDogz Meme Token"></img></a></h1>
          <ul>
            <li>
              <a className="uniswap" href="https://app.uniswap.org/#/tokens/ethereum/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a"target="_blank" rel="noopener noreferrer">Buy on Uniswap</a>
            </li>
            <li>
              <a className="humpBoard" href="#/claim/">Hump Board</a>
            </li>
            <li>
              <a className="strayScan" href="https://straydogz.wtf/stray-scan" target="_blank" rel="noopener noreferrer">Stray Scan</a>
            </li>
            <li>
              <a className="sdOpensea" href="https://opensea.io/Straydogz_wtf/created" target="_blank" rel="noopener noreferrer">StrayDogz NFTs</a>
            </li>
            <li>
              <a className="gifMakerPage" href="https://make.hump.wtf" rel="noopener noreferrer">HumpMaker</a>
            </li>
          </ul>
        </nav>
        <div className={`mobileMenu ${show ? "show" : ""}`}>
          <h1><a href="/"><img src={require('../../imgs/token_img.png')} alt="logo $HUMP StrayDogz Meme Token"></img></a></h1>
          <div className="toggleContainer">
            <button className="toggleMobile" onClick={() => setShow(!show)}>
            </button>
          </div>
          <nav className={`nav`}>
            <ul onClick={() => setShow(!show)}>
              <li>
                <a className="uniswap" href="https://app.uniswap.org/#/tokens/ethereum/0xa44fb2638f53d7d7caa8c67add1ea98b60783c0a" target="_blank" onClick={() => setShow(!show)} rel="noopener noreferrer">Buy on Uniswap</a>
              </li>
              <li>
                <a className="humpBoard" href="#/claim/" onClick={() => setShow(!show)}>Hump Board</a>
              </li>
              <li>
                <a className="strayScan" href="https://straydogz.wtf/stray-scan" target="_blank" onClick={() => setShow(!show)} rel="noopener noreferrer">Stray Scan</a>
              </li>
              <li>
                <a className="sdOpensea" href="https://opensea.io/Straydogz_wtf/created" target="_blank" onClick={() => setShow(!show)} rel="noopener noreferrer">StrayDogz NFTs</a>
              </li>
              <li>
                <a className="gifMakerPage" href="https://make.hump.wtf">HumpMaker</a>
              </li>
            </ul>
          </nav>
        </div>
        <button className="walletButton"
          type='button'
          onClick={connected ? disconnectWallet : connectWallet}
        >
          {connected ? (
            <>
              {" "}
              {""}
              <canvas ref={canvasRef} style={{ display: "none" }} />
              <div className="walletAddress">{shortAddress}</div><div className="connectWallet"></div>
            </>
          ) : (
            <><div className="connectWallet"></div></>
          )}
        </button>
        <div className="social">
          <a href="https://twitter.com/HumpCoin" target="_blank" rel="noopener noreferrer"><i className="icon twitter"></i></a>
          <a href="https://t.me/+FydH0u8S7hliMDE0" target="_blank" rel="noopener noreferrer"><i className="icon telegram"></i></a>
          <a href="https://discord.gg/MbkMkedqaB" target="_blank" rel="noopener noreferrer"><i className="icon discord"></i></a>
          <a href="https://opensea.io/Straydogz_wtf/created" target="_blank" rel="noopener noreferrer"><i className="icon opensea"></i></a>
        </div>
      </header>
    </>
  );
};

export default Header;