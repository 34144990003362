import React from "react";
import GifMaker from "../components/GifMaker";

const HumpMaker = () => {

  return (
    <>
      <GifMaker />
    </>
  );
};

export default HumpMaker;