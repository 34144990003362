import React, { useRef, useState, useEffect } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import RecordRTC from 'recordrtc';
import "./style.css";
import "./sprite.class.js"

const logoHumpMakerUrl = require("../../imgs/logoHumpMaker.png");

export default function GifMaker() {
  const { editor, onReady } = useFabricJSEditor();
  const fileInputRef = useRef(null);
  const [textColor, setTextColor] = useState("#5C4F7B");
  const [brushSize, setBrushSize] = useState(5);
  const [brushShape, setBrushShape] = useState("round");
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [gifRecorder, setGifRecorder] = useState(null);
  const [counter, setCounter] = useState(1);
  let gifChunks = [];

  useEffect(() => {
    if (editor) {
      setCanvasBackgroundImage();
      editor.canvas.freeDrawingBrush.width = brushSize;
      editor.canvas.freeDrawingBrush.strokeWidth = brushSize;
      editor.canvas.freeDrawingBrush.strokeLineCap = brushShape;
      editor.canvas.isDrawingMode = isDrawingMode;
      editor.canvas.freeDrawingBrush.color = textColor;
      return () => {
        editor.canvas.isDrawingMode = false;
      };
    }
  }, [editor, brushSize, brushShape, isDrawingMode]);

  const handleToggleDrawingMode = () => {
    setIsDrawingMode(!isDrawingMode);
  };
  const handleBrushSizeChange = (event) => {
    setBrushSize(parseInt(event.target.value));
  };
  const handleBrushShapeChange = (event) => {
    setBrushShape(event.target.value);
  };
  const handleColorChange = (event) => {
    setTextColor(event.target.value);
  };
  const onImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imgElement = document.createElement("img");
      imgElement.onload = () => {
        const humperImg = new window.fabric.Image(imgElement, {
        hasControls: true,
        padding: 0,
        cornerSize: 5,
        centeredScaling: true,
        centeredRotation: true,
        borderColor: "transparent",
        cornerColor: "transparent",
        cornerStrokeColor: "purple",
      });
        editor.canvas.add(humperImg);
        const scale = Math.min(
          editor.canvas.width / humperImg.width,
          editor.canvas.height / humperImg.height
        );
        humperImg.scale(scale);
        editor.canvas.renderAll();
      };
      imgElement.src = reader.result;
    };
    reader.readAsDataURL(file);
    document.getElementById("inputImg").value = "";
  };
  const onAddText = () => {
    const text = new window.fabric.IText("Hump it!", {
      left: 50,
      top: 50,
      fill: textColor,
      fontFamily:"Headings",
      fontWeight:700,
      hasControls: true,
      padding:0,
      cornerSize: 5,
      centeredScaling:true,
      centeredRotation:true,
      borderColor:"transparent",
      cornerColor: "transparent",
      cornerStrokeColor:"purple",
    });
    editor.canvas.add(text);
    editor.canvas.bringToFront(text);
    editor.canvas.renderAll();
  };
  const onAddHumper = () => {
    window.fabric.Sprite.fromURL(require('../../imgs/leghump_spritesheet.png'), createSprite());
    function createSprite() {
      return function(sprite) {
        sprite.set({
          left: 0,
          top: 0,
          hasControls: true,
          padding:0,
          cornerSize: 5,
          centeredScaling:true,
          centeredRotation:true,
          borderColor:"transparent",
          cornerColor: "transparent",
          cornerStrokeColor:"purple",
        });
        editor.canvas.add(sprite);
        editor.canvas.bringToFront(sprite);
        sprite.set('dirty', true);
        sprite.play();
      };
    }
    (function render() {
      editor.canvas.renderAll();
      window.fabric.util.requestAnimFrame(render);
    })();
  };
  const onDeleteSelected = () => {
    editor.deleteSelected();
  };
  const startRecordingGif = () => {
    const canvas = editor.canvas.getElement();
    const stream = canvas.captureStream();
    const options = {
      type: 'gif',
      frameRate: 16,
      quality: 100,
      width: 250,
      height: 250,
    };
    const recorder = RecordRTC(stream, options);
    setGifRecorder(recorder);
    recorder.startRecording();
  };
  const stopRecordingGif = () => {
    /* clearInterval(timerId); */
    if (gifRecorder) {
      gifRecorder.stopRecording(() => {
        const blob = gifRecorder.getBlob();
        gifChunks.push(blob);
        const fileName = `HumpIT-Hump_WTF-StrayDogz_WTF_${counter}.gif`;
        const dlDiv = document.getElementById("dlDiv");
        const a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(new Blob(gifChunks, { type: 'image/gif' }));
        a.textContent = 'Download GIF';
        dlDiv.appendChild(a);
        gifChunks = [];
        setCounter(counter + 1);
      });
    }
  };
  const handleStartRecording = () => {
    onAddLogo();
    startRecordingGif();
  };
  const onAddLogo = () => {
    window.fabric.Image.fromURL(require("../../imgs/logo_small.png"), function (logoHump) {
      logoHump.set({
      left: 395,
      top: 450,
      selectable: false,
    });
    editor?.canvas.add(logoHump);
    editor?.canvas.bringToFront(logoHump);
    });
  };
  const setCanvasBackgroundImage = () => {
    if (editor.canvas) {
      window.fabric.Image.fromURL(require("../../imgs/background-image.png"), function (backgroundImage) {
        editor.canvas.setBackgroundImage(backgroundImage, editor.canvas.renderAll.bind(editor.canvas), {
          scaleX: editor.canvas.width / backgroundImage.width,
          scaleY: editor.canvas.height / backgroundImage.height
        });
      });
    }
  };
  return (
    <section id="gifMaker">
      <div className="GifMaker">
        <div className="logoHumpMaker">
          <img src={logoHumpMakerUrl} alt="Logo GIF Hump Maker" />
        </div>
        <div className="canvasAndEditorContainer">
          <div className="canvasEditor">
            <div className="firstBlock">
              <label id="deleteSelected">
                Delete selected
                <button className="deleteSelectedEditor" onClick={onDeleteSelected}></button>
              </label>
              <div className="firstLineTools">
                <label className="addPicture">
                  <input id="inputImg" type="file" accept="image/*" ref={fileInputRef} onChange={onImageUpload}/>
                  Add Picture
                </label>
                <button className="addHumperEditor" onClick={onAddHumper}>Add humper</button>
              </div>
              <div className="secondLineTools">
                <button className="addTextEditor" onClick={onAddText}>Add text</button>
                <label className="textBrushColor">Text/Brush Color:</label>
                <input
                  type="color"
                  value={textColor}
                  onChange={handleColorChange}
                />
              </div>
              <div className="DrawingTools">
                <div className="ToggleDrawing">
                  <label>
                    {isDrawingMode ? "Disable Drawing Mode" : "Enable Drawing Mode"}
                  <button className={isDrawingMode ? "Disabled" : "Enabled"} onClick={handleToggleDrawingMode}>
                  </button>
                  </label>
                </div>
                <div className={isDrawingMode ? "Disable" : "Enable"}>
                  <div className="moreDrawingTools">
                    <div className="brushSize">
                      <label>Brush Size:</label>
                      <div className="slidecontainer">
                        <input
                          type="range"
                          min="1"
                          max="20"
                          value={brushSize}
                          onChange={handleBrushSizeChange}
                          className="slider"
                          id="rangeSlide"
                        />
                      </div>
                    </div>
                    <div className="brushShape">
                      <label>Brush Shape:</label>
                      <div className="brushShapeList">
                        <span className="selectBrush">
                          <select value={brushShape} onChange={handleBrushShapeChange}>
                            <option value="round">Round</option>
                            <option value="square">Square</option>
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recordingTools">
              <div id="dlDiv"></div>
              {/* <div>Recording Duration: {recordingDuration} seconds</div> */}
              <button className="startRecordingEditor" onClick={handleStartRecording}>Start Recording GIF</button>
              <button className="stopRecordingEditor" onClick={stopRecordingGif}>Stop Recording GIF</button>
            </div>
          </div>
          <FabricJSCanvas className="sample-canvas" onReady={onReady} />
        </div>
      </div>
    </section>
  );
}