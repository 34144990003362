import { claimAbi, erc721 } from "../../configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../pages/redux/connectWallet";

export { useFetchPoolBalances } from "./fetchPoolBalances";


export function useBalanceOfNFT1(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    const contract = new web3.eth.Contract(erc721, tokenAddress);
    balance = await contract.methods.balanceOf(address).call();
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();
      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useBalanceOfNFT2(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    const contract = new web3.eth.Contract(erc721, tokenAddress);
    balance = await contract.methods.balanceOf(address).call();
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();
      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useTokensOfOwner1(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    const contract = new web3.eth.Contract(erc721, tokenAddress);
    balance = await contract.methods.tokensOfOwner(address).call();
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();
      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useTokensOfOwner2(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    const contract = new web3.eth.Contract(erc721, tokenAddress);
    balance = await contract.methods.tokensOfOwner(address).call();
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();
      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useDeposit(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (tokenIds1, tokenIds2) => {
      setIsPending(true);
      try {
        const nftids1mod = tokenIds1.map(Number)
        const nftids2mod = tokenIds2.map(Number)
        const amount = 0

        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(claimAbi, poolAddress);
          contract.methods
            .claimAllRewards(nftids1mod, nftids2mod)
            .send({ from: address, value: amount })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: "Claiming rewards...",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              console.log(receipt);
              dispatch(
                enqueueSnackbar({
                  message: "Rewards claimed!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                })
              );

              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } catch (error) {
        console.log(error);
        dispatch(
          enqueueSnackbar({
            message: error.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}
