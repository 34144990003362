export const claimpools = [
  /* {
    chainId: 43113,
    name: "test",
    id: "1",
    tokenDecimals: 18,
    tokenAddress: "0x8B1220B9Dcb33068c991b294c325E22aF0391003",   // TokenAddress
    tokenAddress1: "0xbc8d369b906e605acb9fb68545767522268854cb",  // dogz
    tokenAddress2: "0xcdcbb7fca100b0227cd218819805dd2c242ed1f1",  // DHNFT
    earnContractAddress: "0x9bF849EF33e3c328F78201D2906434f50D25A4E6",  // claimContrasct
  }, */
  {
    chainId: 1,
    name: "Claim",
    id: "1",
    tokenDecimals: 18,
    tokenAddress: "0xa44fb2638F53d7D7cAa8c67adD1eA98b60783c0A",   // TokenAddress
    tokenAddress1: "0xea97fC2c61B8FAF98F20ba81AA8A2CF117EB04DC",  // dogz
    tokenAddress2: "0x38175E30Eb68aDac237F27FD456F3138CE5Daacb",  // DHNFT
    earnContractAddress: "0x6297a7457904be2274dD711644AFC7d367f0Cf43",  // claimContrasct
  },

];
