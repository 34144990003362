import React from "react";
import './claim.css';

const DefaultContent = () => {

  return (
    <div className='mainContent claim'>
      <section className='titleDescrImage'>
        <div className='titleDescr'>
          <h2>Hump Board<span>Claim your $Hump for holding StrayDogz and DHNFTs NFTs</span></h2>
          <p>
            <span>5% of $HUMP token and 1% of trades volume</span> is dedicated to reward NFT Holders. DHNFTs have 20% of claim power compared to StrayDogz NFTs
          </p>
          <div className='buyNFTs'>
            <div className='buySD'>
              <a>Buy StrayDogz</a>
              <div className='floorprice'>
                fp
                <span></span>
                ETH
              </div>
            </div>
            <div className='buyDH'>
              <a>Buy DHNFTs</a>
              <div className='floorprice'>
                fp
                <span></span>
                ETH
              </div>
            </div>
          </div>
          <div className='tokenStats'>
            <div className='vol'>
              <h3>24h Volume</h3>
              <div>$
                <span className='valueStats'>1.28M</span>
              </div>
            </div>
            <div className='claimed'>
              <h3>HUMP Claimed</h3>
              <div>
                <span className='valueStats'>1.82B</span>
              </div>
            </div>
            <div className='price'>
              <h3>HUMP Price</h3>
              <div>$
                <span className='valueStats'>0,04586</span>
                <span className='valueamountHump'>per 1k HUMP</span>
              </div>
            </div>
            <div className='mc'>
              <h3>Market Cap</h3>
              <div>$
                <span className='valueStats'>800K</span>
              </div>
            </div>
            <div className='holders'>
              <h3>Holders</h3>
              <div>
                <span className='valueStats'>1200</span>
              </div>
            </div>
          </div>
          <div className='youOwn'>
            <div className='amountNFT'>
              <div className='amountSD'>
                <span> Please change Chain to ETH {' '} </span>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DefaultContent;